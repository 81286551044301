import { LoginService } from '../services/login.service';
import { inject } from '@angular/core';
import {CanActivateFn, Router} from "@angular/router";

export const loginGuard: CanActivateFn = async () => {
  const loginService = inject(LoginService);
  const router = inject(Router);
  if(!window.localStorage.getItem('token')){
    // prevent error on login
    return true
  }
  const authenticated = await loginService.isAuthenticated()
  return authenticated ? router.createUrlTree(['generateUploadUrl']): true;
};
