/**
 * List Files API
 * API to list files in an S3 bucket
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FileDto { 
    /**
     * The name of the file
     */
    Key?: string;
    /**
     * The date and time the file was last modified
     */
    LastModified?: string;
    /**
     * The size of the file in bytes
     */
    Size?: number;
}

