<h1>All Files</h1>
<div class="form">
    <app-navbar [item]=4></app-navbar>

    <div class="join">
        <input #searchInput type="text" id="searchbar" class="input bg-neutral join-item" (input)="search(searchInput.value)" placeholder="search">
        <label for="searchbar" class="join-item bg-neutral icon"><img src="./../../../assets/search.png"></label>
    </div>

    <div class="backgroundTable overflow-x-auto">
        <mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="Key">
                <mat-header-cell *matHeaderCellDef mat-sort-header>File Name</mat-header-cell>
                <mat-cell *matCellDef="let element">{{element.Key}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="LastModified">
                <mat-header-cell *matHeaderCellDef mat-sort-header>LastModified</mat-header-cell>
                <mat-cell *matCellDef="let element">{{ element.LastModified | date:'dd.MM.yyyy HH:mm' }}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="download">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"><button class="btn btn-primary btn-sm" (click)="download(element.Key)">Download</button></mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="tableHeader"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" class="border"></mat-row>

        </mat-table>
    </div>
</div>
