<h1>Login</h1>
<div class="form">
    <label for="admin-token">Admin token</label>
    <div class="inputGroup">
        <div [class.error]="showError" class="inputWrapper join-item tooltip-error tooltip-open" data-tip="Dieser Token ist nicht gültig">
            <input #token id="admin-token" type="text" placeholder="Admin token" class="input">
        </div>

        <button (click)="login(token.value)" class="btn join-item">login</button>
    </div>

</div>
