/**
 * List Files API
 * API to list files in an S3 bucket
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TokenDto { 
    /**
     * The token value
     */
    token?: string;
    /**
     * The expiry date of the token
     */
    expiryDate?: string;
    /**
     * The name of the file associated with the token
     */
    file?: string;
    /**
     * A note associated with the token
     */
    note?: string;
}

