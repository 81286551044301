import { Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { GenerateUploadUrlComponent } from './components/generate-upload-url/generate-upload-url.component';
import { UploadFirmwareComponent } from './components/upload-firmware/upload-firmware.component';
import { validateGuard } from './auth-guards/validate.guard';
import { MangeTokensComponent } from './components/manage-tokens/manage-tokens.component';
import { AllFilesComponent } from './components/all-files/all-files.component';
import {loginGuard} from "./auth-guards/login.guard";

export const routes: Routes = [
    {path: 'login', component: LoginComponent, canActivate: [loginGuard]},
    {path: 'generateUploadUrl', component: GenerateUploadUrlComponent, canActivate: [validateGuard]},
    {path: 'UploadFirmware', component: UploadFirmwareComponent, canActivate: [validateGuard]},
    {path: 'ManageTokens', component: MangeTokensComponent, canActivate: [validateGuard]},
    {path: 'AllFiles', component: AllFilesComponent, canActivate: [validateGuard]},
    {path: '**', redirectTo: 'login'}
];
