import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { BackendService } from 'src/app/services/backend.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-generate-upload-url',
  standalone: true,
  imports: [CommonModule, NavbarComponent],
  templateUrl: './generate-upload-url.component.html',
  styleUrls: ['./generate-upload-url.component.scss']
})
export class GenerateUploadUrlComponent {
  url$: Observable<string> = this.backendService.currentUploadUrl$;

  constructor(private backendService:BackendService) {}

  copy(url: string) {
    navigator.clipboard.writeText(url);
  }

  generateUploadUrl(note: string) {
    this.backendService.generateUploadUrl(note);
  }
}
