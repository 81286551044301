<!-- header with logout button when not on login page -->
<div id="header">
    <img src="./../assets/820838729-Heidelberg_AMPERFIED_Logo_Bildmarke_Verlauf.png" id="amperfied-logo">
    <button *ngIf="(loggedIn$|async)" id="logout" class="btn btn-primary" (click)="logout()">Logout</button>
</div>
<div id="body">
    <div id="item">
        <router-outlet></router-outlet>
    </div>
</div>
