<h1>Generate Upload Url</h1>
<div class="form">
    <app-navbar [item]=0></app-navbar>
    <label>Note</label>
    <input #note class="input" type="text" placeholder="Enter a Note">
    <button class="btn btn-primary" (click)="generateUploadUrl(note.value)">Generate URL</button>
    <div *ngIf="url$ | async as url" id="img">
        <img src="../../../assets/copy.png" id="copy" (click)="copy(url)">
        <p id="url">{{ url }}</p>
    </div>
</div>