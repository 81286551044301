import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from "../navbar/navbar.component";
import { BackendService } from 'src/app/services/backend.service';
import {Subscription} from 'rxjs';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatSort, MatSortModule } from '@angular/material/sort';

@Component({
  selector: 'app-all-files',
  standalone: true,
  templateUrl: './all-files.component.html',
  styleUrls: ['./all-files.component.scss'],
  imports: [CommonModule, NavbarComponent, MatTableModule, MatSortModule]
})
export class AllFilesComponent implements AfterViewInit, OnDestroy{
  private _sub = new Subscription();
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns: string[] = ["Key", "LastModified", "download"]

  @ViewChild(MatSort) sort = new MatSort();
  constructor(private backendService: BackendService) {
    backendService.retrieveAllFiles()
    this._sub.add(backendService.allFiles$.subscribe(
        res => this.dataSource.data = res
    ))
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  public search(input: string): void {
    this.dataSource.filter = input
  }

  public async download(fileName: string) {
    this.backendService.downloadFile(fileName).subscribe(data => {
      var downloadURL = window.URL.createObjectURL(data);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(downloadURL);
    });
  }
  ngOnDestroy() {
    this._sub.unsubscribe()
  }
}
