import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from "../navbar/navbar.component";
import { BackendService } from 'src/app/services/backend.service';
import { MatTableDataSource, MatTableModule} from '@angular/material/table';
import { Subscription} from 'rxjs';
import { MatSort,MatSortModule } from '@angular/material/sort';
import {environment} from "../../../environments/environment";
import {TokenDto} from "@api";

@Component({
  selector: 'app-mange-tokens',
  standalone: true,
  templateUrl: './manage-tokens.component.html',
  styleUrls: ['./manage-tokens.component.scss'],
  imports: [CommonModule, NavbarComponent, MatTableModule, MatSortModule]
})
export class MangeTokensComponent implements AfterViewInit, OnDestroy{
  upload: boolean = true;
  dateTime?: string;
  selectedToken: TokenDto | undefined;
  private _sub = new Subscription();
  public shownTokens: TokenDto[]  = []
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  uploadColumns: string[] = ["token", "copy", "note", "expiryDate", "changeExpiryDate"];
  downloadColumns: string[] = ["token", "copy", "fileName", "note", "expiryDate", "changeExpiryDate"]
  displayedColumns: string[] = this.uploadColumns;
  showToast: boolean = false;

  @ViewChild(MatSort) sort = new MatSort();

  constructor(private backendService: BackendService) {
    backendService.retrieveAllTokens()
    this._sub.add(backendService.allTokens$.subscribe(res => {
      this.shownTokens = res
      this.dataSource.data = res.filter(t => !t.file);
    }));
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  search(input: string): void {
    this.dataSource.filter = input
  }

  setUpload(value: boolean) {
    this.upload = value;
    if (value) {
      this.dataSource.data = this.shownTokens.filter(t => !t.file);
      this.displayedColumns = this.uploadColumns
    }

    else {
      this.dataSource.data = this.shownTokens.filter(t => t.file);
      this.displayedColumns = this.downloadColumns;
    }
  }

  async save(newTime: any) {
    this.selectedToken!.expiryDate = (new Date(newTime).getTime() / 1000).toString()
    this.backendService.putToken(this.selectedToken)
  }

  copy(token: any) {
    if (this.upload)
      navigator.clipboard.writeText(`https://${token.token}@${environment.backendUrl.slice(8)}/upload`);
    else
      navigator.clipboard.writeText(`https://${token.token}@${environment.backendUrl.slice(8)}/download/${token.file}`);

    this.showToast = true;
    setTimeout(() => {
      this.showToast = false;
    }, 1000)
  }

  showModal(modal: any, selectedToken: any) {
    this.selectedToken = selectedToken;
    const localDate = new Date((Number(selectedToken.expiryDate) * 1000));
    // Is necessary so local time is shown in datepicker instead of utc
    let day = localDate.getDate().toString();
    if (day.length == 1)
      day = "0" + day;
    let month = (localDate.getMonth() + 1).toString();
    if (month.length == 1)
      month = "0" + month;
    let hours = (localDate.getHours()).toString();
    if (hours.length == 1)
      hours = "0" + hours;
    let minutes = (localDate.getMinutes()).toString();
    if (minutes.length == 1)
      minutes = "0" + minutes;
    this.dateTime = `${localDate.getFullYear()}-${month}-${day}T${hours}:${minutes}`
    modal.showModal();
  }
  ngOnDestroy() {
    this._sub.unsubscribe()
  }
}
