import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {BehaviorSubject,Observable} from 'rxjs';
import {TokenService} from "@api";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _loggedIn$ = new BehaviorSubject<boolean>(false);
  constructor(
   private router: Router,
   private tokenService: TokenService
  ) { }

  public login(token: string) {
      return new Promise<boolean>(async resolve => {
          const authenticated = await this.isAuthenticated(token)
          if(authenticated){
              window.localStorage.setItem('token',token)
              this.router.navigate(['generateUploadUrl'])
          }
          resolve(authenticated)
      })
  }

  public isAuthenticated(token?:string){
      return new Promise<boolean>(resolve => {
          this.tokenService.validateAdminTokenGet(token,'response').subscribe({
              next: () => {
                  this._loggedIn$.next(true)
                  resolve(true)
              },
              error: () => {
                  this._loggedIn$.next(false)
                  resolve(false)
              }
          })
      })

  }


  public logout() {
    window.localStorage.removeItem('token');
    this._loggedIn$.next(false)
    this.router.navigate(['login'])
  }


  get loggedIn$(): Observable<boolean> {
    return this._loggedIn$.asObservable();
  }

}
