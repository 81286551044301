import { HttpInterceptorFn } from "@angular/common/http";
import {EMPTY, NEVER, tap} from "rxjs";
export const AuthInterceptor: HttpInterceptorFn = (req, next) => {
    const token = window.localStorage.getItem("token") || ""
    let headers = req.headers
    if(!headers.get('Authorization')){
        headers = req.headers.set('Authorization', token);
    }
    req = req.clone({
        headers
    });

    return next(req).pipe(
        tap(() => {
        })
    );
}
