import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AuthInterceptor } from './interceptor/auth.interceptor.service';
import { provideAnimations } from '@angular/platform-browser/animations';
import {environment} from "../environments/environment";
import {ApiModule, Configuration} from "@api";

export const appConfig: ApplicationConfig = {

  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([AuthInterceptor])),
    provideAnimations(),
    importProvidersFrom(ApiModule.forRoot(
      () =>
        new Configuration({
          basePath: environment.backendUrl,
          withCredentials: false,
        })
    ))
  ]
};
