import {CanActivateFn, Router} from '@angular/router';
import { LoginService } from '../services/login.service';
import { inject } from '@angular/core';

export const validateGuard: CanActivateFn = async () => {
  const loginService = inject(LoginService);
  const router = inject(Router);
  const authenticated = await loginService.isAuthenticated()
  return authenticated ? true : router.createUrlTree(['login'])
};
