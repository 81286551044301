<h1>Manage Tokens</h1>
<div class="form">
    <app-navbar [item]=3></app-navbar>
    <div class="join">
        <input #searchInput type="text" id="searchbar" class="input bg-neutral join-item"
               (input)="search(searchInput.value)" placeholder="search">
        <label for="searchbar" class="join-item bg-neutral icon"><img src="./../../../assets/search.png"></label>
    </div>


    <div class="backgroundSwitch">
        <button (click)="setUpload(true)" class="btn btn-accent" [class.active]="upload">Upload</button>
        <button (click)="setUpload(false)" class="btn btn-accent" [class.active]="!upload">Download</button>
    </div>

    <div class="backgroundTable overflow-x-auto">
        <mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="token">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Token </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.token}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="copy">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element" class="copy"><img src="./../../../assets/kopieren.png">
                </mat-cell>
            </ng-container>

            <ng-container *ngIf="!upload" matColumnDef="fileName">
                <mat-header-cell *matHeaderCellDef mat-sort-header="file"> File Name </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.file}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="note">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Note </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.note}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="expiryDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header> Expiry Date </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.expiryDate*1000 | date:'dd.MM.yyyy HH:mm'}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="changeExpiryDate">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let element"><button class="btn btn-primary btn-sm"
                        (click)="showModal(modal, element)">change date</button></mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns" class="tableHeader"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="copy(row)" class="border"></mat-row>
        </mat-table>
    </div>

    <dialog #modal class="modal ">
        <form method="dialog" class="modal-box">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            <h3 class="font-bold text-lg">change expiry Date</h3>
            <input #date type="datetime-local" id="datepicker" class="input bg-neutral" value="{{dateTime}}">
            <button id="save" class="btn btn-primary" (click)="save(date.value)">speichern</button>
        </form>
    </dialog>
</div>

<div class="toast toast-center" *ngIf="showToast">
    <div class="alert alert-success">
        <span>Link copied!</span>
    </div>
</div>
