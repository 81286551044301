<h1>Upload Firmware<label></label></h1>
<div class="form">
    <app-navbar [item]=2></app-navbar>
    <div [class.error]="wrongFileName$ | async" class="inputWrapper join-item tooltip-error tooltip-open"
        data-tip="Eine Datei mit diesem Namen existiert bereits">
        <input #file id="file" type="file" class="file-input" (change)="resetTooltip()">
    </div>
    <label>Note</label>
    <input #note id="note" type="text" class="input" placeholder="Enter a Note">
    <button class="btn btn-primary" (click)="upload(file.files, note.value)">Get Download URL</button>
    <div *ngIf="url$ | async as url" id="img">
        <img src="../../../assets/copy.png" id="copy" (click)="copy(url)">
        <p id="url">{{ url }}</p>
    </div>
</div>