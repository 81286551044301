import { Injectable } from '@angular/core';
import {BehaviorSubject, concatMap, Observable} from 'rxjs';
import {FileDto, FileService, TokenDto, TokenService} from "@api";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  private _currentUploadUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private _currentDownloadUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private _wrongFileName$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _allFiles$ = new BehaviorSubject<FileDto[]>([]);
  private _allTokens$ = new BehaviorSubject<TokenDto[]>([]);

  constructor(
    private fileService: FileService,
    private tokenService: TokenService,
    private http: HttpClient
  ) { }

  public generateUploadUrl(note: string): void {
    this.tokenService.accessGet("PUT",undefined, note).subscribe((res:string) => this._currentUploadUrl$.next(res))
  }

  public uploadFile(file: File, note: string): void {
    this.http.put(`${environment.backendUrl}/upload/${file.name}`,file)
        .pipe(
            concatMap(() => {
              return this.tokenService.accessGet("GET", file.name, note)
            })
        )
        .subscribe({
          next: (res:string) => this._currentDownloadUrl$.next(res),
          error: (e) => {
            console.log(e);
            this._currentDownloadUrl$.next("");
            this._wrongFileName$.next(true);
        },
    })
  }

  public downloadFile(fileName: string): Observable<Blob> {
    return this.fileService.downloadFilenameGet(fileName);
  }

  public retrieveAllTokens(): void {
    this.tokenService.tokensGet().subscribe({
      next: res => {
        this._allTokens$.next(res)
      },
      error: e => {
        // TODO error handling
      }
    });
  }

  public putToken(token: any) {
   return this.tokenService.updateTokenExpiryPut(token).subscribe({
     next: () => {
       // TODO success feedback to user
     },
     error: () => {
       //TODO error handling
     }
   });
  }

  public retrieveAllFiles():void{
    this.fileService.listFilesGet().subscribe({
      next: (res: FileDto[]) => {
        this._allFiles$.next(res)
      },
      error: (e: HttpErrorResponse) => {
        //TODO error handling
      }
    })
  }

  get allFiles$(): Observable<FileDto[]> {
    return this._allFiles$.asObservable();
  }
  resetWrongFileName() {
    this._wrongFileName$.next(false);
  }

  get currentUploadUrl$(): Observable<string> {
    return this._currentUploadUrl$.asObservable();
  }

  get currentDowloadUrl$(): Observable<string> {
    return this._currentDownloadUrl$;
  }

  get wrongFileName$(): Observable<boolean> {
    return this._wrongFileName$.asObservable();
  }


  get allTokens$(): Observable<TokenDto[]> {
    return this._allTokens$.asObservable();
  }
}
