import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from '../navbar/navbar.component';
import { BackendService } from 'src/app/services/backend.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-upload-firmware',
  standalone: true,
  imports: [CommonModule, NavbarComponent],
  templateUrl: './upload-firmware.component.html',
  styleUrls: ['./upload-firmware.component.scss']
})
export class UploadFirmwareComponent {
  url$: Observable<string> = this.backendService.currentDowloadUrl$;
  wrongFileName$: Observable<boolean> = this.backendService.wrongFileName$;

  constructor(private backendService: BackendService) {
    this.resetTooltip();
  }

  copy(url: string) {
    navigator.clipboard.writeText(url);
  }

  upload(file: FileList | null, note: string) {
    if (file != null && file.item(0) != null)
      this.backendService.uploadFile(file.item(0)!, note);
  }

  resetTooltip() {
    this.backendService.resetWrongFileName();
  }
}
